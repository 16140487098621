import React from "react";

const BuildingIcon = ({ color }) => {
    if (!color || color && color.length === 0) color = 'red'
    let colors = {
        "yellow": ["#ffdea0", "#ffae18"],
        "green": ["#139d8b", "#8bc9c3"],
        "red": ["#ff5145", "#ff9189"],
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="44"><path fill={colors[color][0]} d="M0 0h23v44H0z"/><path fill={colors[color][1]} d="M5 7h6v6H5zM5 15h6v6H5zM5 23h6v6H5zM13 7h6v6h-6zM13 15h6v6h-6zM13 23h6v6h-6z"/></svg>
    )
}

export default BuildingIcon;
