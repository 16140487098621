import React from "react";

const MoneyIcon = ({ color }) => {

    if (!color || color && color.length === 0) color = 'yellow'
    let colors = {
        "yellow": ["#ffae18", "#ffae18", "#ffdea0"],
        "red": ["#ff5145", "#f0a19c", "#ffcbc7"]
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="59.602" height="43.9"><path fill={colors[color][0]} d="M0 17.209h47v23H0z"/><path fill={colors[color][1]} d="M7.777 9.519l45.402 12.173-5.957 22.218L1.82 31.737z"/><path fill={colors[color][2]} d="M18.899 0l40.703 23.5-11.5 19.919L7.4 19.919z"/></svg>
    )
}

export default MoneyIcon;
