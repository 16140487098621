import React from "react";

const BriocheIcon = ({ color }) => {

    if (!color || color && color.length === 0) color = 'yellow'
    let colors = {
        "yellow": ["#ffae18", "#ffdea0"],
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46.397" height="45.155"><path d="M4.544 30.083s-9.267-9.415-1.333-18.962 12.851-1.208 12.851-1.208S14.816-.25 26.885.005s9.05 11.116 9.05 11.116 4.882-1.189 8.4 4.961 2.328 11.211-2 14-37.791.001-37.791.001z" fill={colors[color][0]} /><path d="M4.544 30.084l7.867 15.072h22.853l7.118-15.072z" fill={colors[color][1]}/></svg>
    )
}

export default BriocheIcon;
