import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Cookie from "../components/cookie"
import Transition from "../components/transition"

import { ContextProviderComponent } from "../components/context"

import "../styles/main.scss"

const Layout = (pageProps) => {

  // console.log(pageProps);

  return (
    <ContextProviderComponent locale={pageProps.pageContext.locale}>

      <Cookie/>

      <Header data={pageProps.data} path={pageProps.path} locale={pageProps.pageContext.locale} />

      <Transition location={pageProps.location} className="u-pd-navbar-top">
        { pageProps.children }
      </Transition>

      <Footer data={pageProps.data} />

    </ContextProviderComponent>
  )
}

export default Layout
