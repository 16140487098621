import React from "react";

const CaseIcon = ({ color }) => {
    if (!color || color && color.length === 0) color = 'green'
    let colors = {
        "yellow": ["#ffae18", "#ffdea0"],
        "green": ["#139d8b", "#8bc9c3"],
        "red": ["#ff5145", "#ffcbc7"],
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49.011" height="37"><g transform="translate(-486.996 -631)"><g transform="translate(500 631)" stroke={colors[color][0]} strokeWidth="3" fill="none"><rect width="22" height="11" rx="2" stroke="none"/><rect x="1.5" y="1.5" width="19" height="8" rx=".5"/></g><path fill={colors[color][0]} d="M487 637h49v31h-49z"/><path fill={colors[color][1]} d="M511.606 659.009C486.088 659.009 487 637 487 637h49s1.125 22.009-24.394 22.009z"/><rect fill={colors[color][1]} width="4" height="6" rx="2" transform="translate(510 656)"/></g></svg>
    )
}

export default CaseIcon;
