import locales from '../constants/locales'

function formatUrl(url) {
    return (url + '/').replace(/\/\//g, "/");
}

function getRevertedRouting(routingToRevert) {
    const result = {};
    const routing = locales[routingToRevert].routing
    Object.keys(routing).forEach(key => {
      result[routing[key]] = key;
    });
    return result;
  }

export { formatUrl, getRevertedRouting };