
import React from "react"
import { Helmet } from "react-helmet"

class Cookie extends React.Component {

    /* eslint-disable */

    showCookieConsent() {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#fcf6f3"
                },
                "button": {
                    "background": "#FFAE18",
                    "border": "#212121",
                    "text": "#212121"
                }
            },
            "position": "bottom-right",
            "type": "opt-in",
            "content": {
                "message": "Ce site utilise des cookies de pistage afin de réaliser des statistiques de visites. Ces cookies ne sont déposés que si vous donnez votre accord en cliquant sur le bouton Accepter. Ces données ne seront pas utilisées pour vous identifier ou vous contacter.",
                "allow": "Accepter",
                "dismiss": "Refuser",
                "deny": "Refuser",
                "link": "En savoir plus",
                "href": "https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs",
                "policy": "<span class='icon-cookies u-primary u-mg-right-xs'></span> Cookies"
            },
            onInitialise: function (status) {
                var type = this.options.type;

                var didConsent = false;

                if (status === 'allow'){
                    didConsent = true;
                }

                if (type == 'opt-in' && didConsent) {
                    console.log('consent given');
                    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                    
                    ga('create', 'UA-XXXXXX', 'auto');
                    ga('send', 'pageview');
                }
                else {
                    console.log('did not consent');
                }
            },
            onStatusChange: function(status, chosenBefore) {
                var type = this.options.type;
                var didConsent = false;

                if (status === 'allow'){
                    didConsent = true;
                }

                if (type == 'opt-in' && didConsent) {
                    console.log('consent given');
                    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
                    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
                    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
                    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
                    
                    ga('create', 'UA-XXXXXX', 'auto');
                    ga('send', 'pageview');
                }
                else {
                    console.log('did not consent');
                }
            },
        })
    }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js";
    script.async = true;
    script.onload = () => {
        this.showCookieConsent()
    };

    document.body.appendChild(script);

  }

  render(props) {

    return (
      <React.Fragment>
        <Helmet>
            <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css" />
            {/* <script src="https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js"></script> */}
        </Helmet>
      </React.Fragment>
    )
  }
}

export default Cookie
