import React from "react";

const BolIcon = ({ color }) => {
    if (!color || color && color.length === 0) color = 'green'
    let colors = {
        "yellow": ["#ffdea0", "#ffae18"],
        "green": ["#08413B", "#139D8B"],
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="47.722" height="43.458"><path fill={colors[color][0]} d="M31.075 8.571l6.06 6.06-6.06 6.061-6.06-6.06zM23.728 0l6.06 6.06-6.06 6.061-6.06-6.06zM21.168 12.177l1.34 8.465-8.465 1.341-1.34-8.465z"/><path fill={colors[color][1]} d="M12.267 40.458h24v3h-24z"/><path fill={colors[color][1]} d="M47.722 17.074a23.861 23.861 0 11-47.722 0z"/></svg>
    )
}

export default BolIcon;
