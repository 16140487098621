import React from "react"
import LogoPalace from "../components/svg/logo-palace"
import Logo from "../components/svg/logo"
import LocalizedLink from "../components/localizedLink"
import ImgPolyfill from "gatsby-image/withIEPolyfill"
import IconComponent from "../components/icon"

const Footer = ({ data }) => {

    const content = data.file.childContentJson.global.footer;

    return (
        <footer className={"c-footer"}>

            <div className="l-grid">

                <div className="l-col-12 l-col-3@main u-pd-vt-l u-flex u-flex-dir-col u-flex-center-hz u-flex-center-vt">
                    <div className="u-pd-l">
                        <Logo color="white" />
                    </div>
                    <span className="u-white">{content.by}</span>
                    <a href="https://www.startup-palace.com/" target="_blank" rel="noopener noreferrer" aria-label={'Startup Palace'}>
                        <LogoPalace style={{ width: "120px" }} />
                    </a>
                </div>

                <div className="l-col-12 l-col-7@main u-pd-vt-l@main u-flex u-flex-dir-col u-flex-dir-row@main u-flex-center-vt u-flex-center-hz@main">

                    <nav className="c-footer__nav u-pd-hz-xxl@main u-pd-bottom-xl u-pd-bottom-0@main">
                        <div className="u-pd-hz-l@main u-pd-bottom-xl u-pd-bottom-0@main">
                            <a href="https://en.futuragrow.fr/" rel="noopener" className="c-footer__link u-mg-vt-m">English Version</a>
                        </div>
                        <div className="u-pd-hz-l@main u-pd-bottom-xl u-pd-bottom-0@main">
                            <LocalizedLink to="/mentions-legales" className="c-footer__link u-mg-vt-m">{content.cgu}</LocalizedLink>
                        </div>
                        <div className="u-pd-hz-l@main u-pd-bottom-xl u-pd-bottom-0@main" dangerouslySetInnerHTML={{__html: `<a href="mailto:${content.email}" class="c-footer__link u-mg-vt-m">${content.contact}</a>`}}></div>
                        <div className="c-footer__social u-pd-hz-xl@main u-pd-bottom-xl u-pd-bottom-0@main">
                            {/* <h3 className="c-h4 u-white u-fs-m u-mg-vt-s u-capitalize">{content.social.title}</h3> */}
                            <div className="u-flex">
                                <a href="https://www.linkedin.com/company/futuragrow/about/" target="_blank" rel="noopener noreferrer" className="c-footer__link u-normal u-mg-vt-s u-mg-right-l" aria-label={content.social.linkedin}>
                                    <IconComponent name={"linkedin"} />
                                </a>
                                <a href="https://twitter.com/FuturaGrow" target="_blank" rel="noopener noreferrer" className="c-footer__link u-normal u-mg-vt-s u-mg-right-l" aria-label={content.social.twitter}>
                                    <IconComponent name={"twitter"} />
                                </a>
                            </div>
                        </div>
                    </nav>

                </div>

                <div className="l-col-12 l-col-2@main u-pd-vt-l u-flex u-flex-dir-col u-flex-center-hz u-flex-center-vt">
                    {data.file.childContentJson.global.partners.map((partner, index) => {
                        let image = data.partnersLogo.nodes.find(item => item.name === partner.image)
                        return (
                            <a href={partner.link} key={"footer-partner-" + index} target="_blank" aria-label={partner.name} rel="noopener noreferrer">
                                <ImgPolyfill
                                    fixed={image.childImageSharp.fixed}
                                    objectFit="contain"
                                    className="u-mg-s"
                                    alt={partner.name}
                                />
                            </a>
                        )
                    })}
                </div>

            </div>

        </footer>
    )
}

export default Footer
