import React from "react"

const { Provider, Consumer } = React.createContext()

// const useLocale = () => React.useContext(locale);

class ContextProviderComponent extends React.Component {

    render() {

        const { children, locale } = this.props

        return (
            <Provider value={locale}>
                {children}
            </Provider>
        )
    }
}

export { Consumer as default, ContextProviderComponent }