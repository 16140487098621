import React from 'react'

import { Link } from 'gatsby'
import locales from '../constants/locales'
import Consumer from "../components/context"

const LocalizedLink = ({ to, ...props }) => (
    <Consumer>
        {locale => {
            // if locale is default, go to `to`
            // else add the locale in the path
            const formatUrl = (url) => (url + '/').replace(/\/\//g, "/");
            const path = locales[locale].default
                ? to
                : `/${locales[locale].path}${locales[locale].routing[formatUrl(to)] || to}`

            return <Link {...props} to={path} />
        }}
    </Consumer>

)

export default LocalizedLink