import React from "react";

const PhoneIcon = () => {
return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32.228" height="48.198">
        <g transform="rotate(10.02 662.145 -2294.868)">
            <g>
                <rect width="24.869" height="44.556" rx="2.367" transform="translate(417 79)" fill="#F29D00"/>
                <path fill="#FFAE18" d="M417 85.855h24.869v28.562H417z"/>
            </g>
        </g>
    </svg>
)}

export default PhoneIcon;
