import React from "react";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

const CarotteIcon = ({ hover }) => {

    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        setIsHovered(hover);
    }, [hover]);

    return (

        <motion.svg 
            width="86.822"
            height="38.665"
            viewBox="0 0 86.822 38.665">

            <g transform="translate(-199.647 42.576) rotate(-30)">
                <motion.path 
                    d="M220.967,95.607l-9.959-11.216L198.517,98.625l12.768,8.078A94.61,94.61,0,0,1,220.967,95.607Z" 
                    initial={{ translateX: 0, translateY: 0}}
                    animate={{ translateX: isHovered ? -1.366 : 0, translateY: isHovered ? 0.366 : 0 }}
                    fill="#FFAE18"/>
                <motion.path 
                    d="M220.967,95.607a94.61,94.61,0,0,0-9.682,11.1l9.3,5.886a48.286,48.286,0,0,1,8.45-7.894Z" 
                    initial={{ translateX: 0, translateY: 0}}
                    animate={{ translateX: isHovered ? 4.598 : 0, translateY: isHovered ? -1.964 : 0 }}
                    fill="#FFAE18"/>
                <motion.path 
                    d="M229.037,104.695a48.286,48.286,0,0,0-8.45,7.894l8.541,5.4a54.182,54.182,0,0,1,6.441-5.943Z"
                    initial={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                    animate={{ transform: isHovered ? "matrix(0.998, -0.07, 0.07, 0.998, -6.613, 21.145)" : "matrix(1, 0, 0, 1, 0, 0)" }}
                    className={"u-to-0"}
                    fill="#FFAE18"/>
                <motion.path 
                    d="M235.569,112.05a54.182,54.182,0,0,0-6.441,5.943l7.517,4.755a17.221,17.221,0,0,0,4.457-4.466Z" 
                    initial={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                    animate={{ transform: isHovered ? "matrix(1, -0.017, 0.017, 1, 5.049, 5.889)" : "matrix(1, 0, 0, 1, 0, 0)" }}
                    className={"u-to-0"}
                    fill="#FFAE18"/>
                <motion.path 
                    d="M241.1,118.282a17.221,17.221,0,0,1-4.457,4.466l7.785,4.926,2.251-3.109Z" 
                    initial={{ translateX: 0, translateY: 0}}
                    animate={{ translateX: isHovered ? 6.794 : 0, translateY: isHovered ? 6.232 : 0 }}
                    fill="#FFAE18"/>
                <motion.path 
                    d="M203.929,88.954s3.987-5.642,3.112-10.472c-.726-4.015-4.924-5.728-4.924-5.728a28.5,28.5,0,0,0-2.222,7.365C199.294,84.451,203.929,88.954,203.929,88.954Z" 
                    initial={{ translateX: 0, translateY: 0}}
                    animate={{ translateX: isHovered ? 0.134 : 0, translateY: isHovered ? -2.232 : 0}}
                    fill="#FFAE18"/>
                <motion.path 
                    d="M202.23,90.712s-4.558-3.659-6.4-3.673c-5.626-.042-12.082,3.783-12.082,3.783s6.4,3.294,8.883,3.789C197.821,95.646,202.23,90.712,202.23,90.712Z" 
                    initial={{ translateX: 0, translateY: 0}}
                    animate={{ translateX: isHovered ? -3.098 : 0, translateY: isHovered ? -0.634 : 0}}
                    fill="#FFAE18"/>
            </g>
        </motion.svg>
    )
}

export default CarotteIcon;

