import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import { useScrollPosition } from "../components/utils/scrollPosition"

import Logo from "../components/svg/logo"
import CarotteIcon from "../components/svg/carotte-icon"
import LocalizedLink from "../components/localizedLink"

import locales from '../constants/locales'
import { formatUrl, getRevertedRouting } from '../utils/i18n'

const Header = ({ path, locale, data }) => {

  const content = data.file.childContentJson.global.header;
  const subscriptionLink = data.file.childContentJson.global.subscriptionLink;

  const [isHoverOnCTA, setIsHoverOnCTA] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [navTranslate, setNavTranslate] = useState('0');

  const handleScrollMenu = (prevPos, currPos) => {
    if (currPos.y >= -120) {
      setNavTranslate(currPos.y + 'px')
    }
    else {
      let isVisible = currPos.y > prevPos.y
      setNavTranslate(isVisible ? '0' : '-100%')
    }
  }

  useScrollPosition(({ prevPos, currPos }) => {
    handleScrollMenu(prevPos, currPos)
  },[navTranslate])

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    // Side effect
    document.querySelector('body').classList[isMenuOpen ? 'remove' : 'add']("u-overflow-hidden");
  }

  // const handleLocaleToggle = (newLocale) => {

  //   const previousLocale = locale;
  //   // url without lang
  //   let cleanPath = locales[previousLocale].default ? path : path.slice(3)

  //   // NewUrl => translation only works with 2 locales for now
  //   let newUrl = locales[newLocale].default
  //     ? (locales[previousLocale].default
  //         // newLocale == default && previousLocale == default => return the cleanPath
  //         ? cleanPath
  //         // newLocale == default && previousLocale != default => return the reverted route from previousLocale
  //         : getRevertedRouting(locale)[formatUrl(cleanPath)])
  //       // newLocale != default => return the url from routing object
  //     : `/${locales[newLocale].path}${locales[newLocale].routing[formatUrl(cleanPath)]}`


  //   navigate(newUrl);
  // }

  const handleActiveNavLink = (viewName) => {
    let url = locales[locale].default ? path : getRevertedRouting(locale)[path.slice(3)]
    let className = url.replace(/\//g, "") === viewName ? "is-active" : "";
    return className
  }

  return (

    <header className={"c-header u-pd-hz-xxl" + (isMenuOpen ? " is-open" : "")} style={{ transform: `translateY(${navTranslate})` }}>

        <div className="u-flex u-flex-between u-flex-center-vt u-height-full">

          <div className="c-header__logo u-pd-hz-m u-pd-hz-0@main">
            <LocalizedLink to={'/'} className={"c-logo"} aria-label={'Logo FuturaGrow'}>
                <Logo />
            </LocalizedLink>
          </div>

          <div className="c-header__menu">

            <ul className="c-header__nav u-reset-list u-pd-hz-s u-pd-hz-l@main">
              <li className="u-pd-hz-m@main"><LocalizedLink to="/accelerateur" className={"c-header__link " + handleActiveNavLink('accelerateur')}>{content.navigation.accelerator}</LocalizedLink></li>
              <li className="u-pd-hz-m@main"><LocalizedLink to="/programme" className={"c-header__link " + handleActiveNavLink('programme')}>{content.navigation.program}</LocalizedLink></li>
              <li className="u-pd-hz-m@main"><LocalizedLink to="/thematiques" className={"c-header__link " + handleActiveNavLink('thematiques')}>{content.navigation.thematic}</LocalizedLink></li>
              <li className="u-pd-hz-m@main"><LocalizedLink to="/equipe" className={"c-header__link " + handleActiveNavLink('equipe')}>{content.navigation.team}</LocalizedLink></li>
              <li className="u-pd-hz-m@main"><a href="https://futuragrow.medium.com/" target="_blank" rel="noopener" className="c-header__link">{content.navigation.blog}</a></li>
              <li className="u-pd-hz-m@main"><a href="https://en.futuragrow.fr/" target="_blank" rel="noopener" className="c-header__link">{content.navigation.english}</a></li>
              {/* <li className="u-pd-hz-m@main"><LocalizedLink to="/" className={"c-header__link " + handleActiveNavLink()}>{content.navigation.blog}</LocalizedLink></li> */}
            </ul>

            <a href={subscriptionLink} target="_blank" rel="noopener noreferrer" className="c-header__cta u-pd-hz-l u-pd-hz-0@main u-mg-0 u-mg-left-l@main"
              onMouseEnter={() => setIsHoverOnCTA(true)}
              onMouseLeave={() => setIsHoverOnCTA(false)}>

              <div className="c-header__cta-content">
                <CarotteIcon hover={isHoverOnCTA} />
                <span>{content.candidate}</span>
              </div>

            </a>

            {/* <div className="c-header__lang u-pd-right-m">
              <button onClick={() => handleLocaleToggle('fr')} role="button" className={"u-pd-xs" + (locale === 'fr' ? " is-active" : " ") }>{content.lang.fr}</button>
              <button onClick={() => handleLocaleToggle('en')} role="button" className={"u-pd-xs" + (locale === 'en' ? " is-active" : " ") }>{content.lang.en}</button>
            </div> */}

          </div>

          <button className={"c-header__toggle u-pd-hz-m u-clickable" + (isMenuOpen ? " is-open" : "")} onClick={() => handleMenuToggle()}>
            <span></span>
          </button>

        </div>

    </header>
  )

}

export default Header
