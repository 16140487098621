import React from "react";
// import { motion } from "framer-motion";

const PanierIcon = ({ color }) => {
    if (!color || color && color.length === 0) color = 'red'
    let colors = {
        "yellow": "#ffae18",
        "green": "#139d8b",
        "red": "#ff5145",
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50.575" height="32.24">
            <path
                fill={colors[color]}
                d="M20.93 0l1.505 1.505L9.057 14.884l-1.505-1.506zM43.524 13.379l-1.505 1.505L28.64 1.506 30.146.001z"
            />
            <path
                fill={colors[color]}
                d="M0 11.924l10.516 20.317h30.545l9.514-20.317z"
            />
        </svg>
    )
}

export default PanierIcon;
