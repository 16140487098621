import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ meta, title, noTitleTemplate = false }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteLanguage
            siteUrl
            author
          }
        }
        images: allFile(
          filter: {extension: {regex: "/(jpg)|(jpeg)|(ico)|(png)/"},
          relativeDirectory: {eq: "img/icons"}}
        ) {
          nodes {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            name
          }
        }
      }
    `
  )

  const metaImage = data.images.nodes.filter((item) => item.name === "android-chrome-192x192")[0].childImageSharp.fluid.src;;
  const url = data.site.siteMetadata.siteUrl;
  const metaTitle = data.site.siteMetadata.title;
  const metaDescription = data.site.siteMetadata.description;
  const metaFavicon16 = data.images.nodes.filter((item) => item.name === "favicon-16x16")[0].childImageSharp.fluid.src;
  const metaFavicon32 = data.images.nodes.filter((item) => item.name === "favicon-32x32")[0].childImageSharp.fluid.src;

  const metaLanguage = data.site.siteMetadata.siteLanguage
  const metaAuthor = data.site.siteMetadata.author;

  return (
    <Helmet
      htmlAttributes={{
        lang: metaLanguage,
      }}
      title={title}
      titleTemplate={noTitleTemplate ? "%s" : `%s | ${metaTitle}`}
      link={[
        { rel: "icon", type: "image/png", sizes: "16x16", href: `${metaFavicon16}` },
        { rel: "icon", type: "image/png", sizes: "32x32", href: `${metaFavicon32}` }
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: url + metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: url + metaImage,
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

export default SEO
