import React from "react"

import FlowerIcon from "../components/svg/flower-icon"
import BullesIcon from "../components/svg/bulles-icon"
import BolIcon from "../components/svg/bol-icon"
import BocalIcon from "../components/svg/bocal-icon"
import RestoIcon from "../components/svg/resto-icon"
import PhoneIcon from "../components/svg/phone-icon"
import BriocheIcon from "./svg/brioche-icon"

import BuildingIcon from "../components/svg/building-icon"
import MoneyIcon from "../components/svg/money-icon"
import CaseIcon from "../components/svg/case-icon"
import PanierIcon from "../components/svg/panier-icon"

class Icon extends React.Component {

    constructor(props) {
        super();
    }

    renderIcon(name) {
        // console.log(this.props.color)
        switch(name) {
            case 'flower':
                return <FlowerIcon color={this.props.color} />;
            case 'brioche':
                return <BriocheIcon color={this.props.color} />;
            case 'bulles':
                return <BullesIcon color={this.props.color} />;
            case 'bol':
                return <BolIcon color={this.props.color} />;
            case 'bocal':
                return <BocalIcon color={this.props.color} />;
            case 'resto':
                return <RestoIcon color={this.props.color} />;
            case 'phone':
                return <PhoneIcon color={this.props.color} />;
            case 'building':
                return <BuildingIcon color={this.props.color} />;
            case 'money':
                return <MoneyIcon color={this.props.color} />;
            case 'case':
                return <CaseIcon color={this.props.color} />;
            case 'panier':
                return <PanierIcon color={this.props.color} />;
            case 'linkedin':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="26" height="26"><path fill="currentColor" d="M0 160h114.496v352H0zm426.368 4.128c-1.216-.384-2.368-.8-3.648-1.152a74.218 74.218 0 00-4.64-.896c-6.08-1.216-12.736-2.08-20.544-2.08-66.752 0-109.088 48.544-123.04 67.296V160H160v352h114.496V320s86.528-120.512 123.04-32v224H512V274.464c0-53.184-36.448-97.504-85.632-110.336z"/><circle fill="currentColor" cx="56" cy="56" r="56"/></svg>
                );
            case 'twitter':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="26" height="26"><path fill="currentColor" d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"/></svg>
                )
}
      }

    render() {
        const { name } = this.props;
        return (
            <>
                {this.renderIcon(name)}
            </>
        )
    }

    
}
    
export default Icon