import React from "react";

const BocalIcon = ({ color }) => {

    if (!color || color && color.length === 0) color = 'yellow'
    let colors = {
        "yellow": ["#ffae18", "#ffdea0"],
        "green": ["#139D8B", "#8BC9C3"],
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="38.038" height="49.596"><g transform="translate(-321.104 -1133.829)"><path fill={colors[color][0]} d="M322.564 1149.941l7.71 30.923 2.431 2.115 24.379-6.078 1.418-3.079-7.573-30.375-3.097-3.31-23.956 5.973a39.5 39.5 0 01-1.312 3.831zM321.104 1140.441l26.518-6.612.853 3.422-26.518 6.611z"/><path fill={colors[color][1]} d="M337.479 1156.137l5.592 5.592-5.592 5.592-5.592-5.592zM349.742 1164.453l3.09 7.28-7.28 3.09-3.09-7.28z"/><circle fill={colors[color][1]} cx="4.5" cy="4.5" r="4.5" transform="translate(339 1146.721)"/><ellipse fill={colors[color][1]} cx="4.5" cy="5" rx="4.5" ry="5" transform="translate(332 1169.721)"/></g></svg>
    )
}

export default BocalIcon;
