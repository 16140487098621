import React from "react";
// import { motion } from "framer-motion";

const FlowerIcon = ({ color }) => {
    if (!color || color && color.length === 0) color = 'green'
    let colors = {
        "yellow": ["#ffae18", "#ffae18", "#ffdea0"],
        "green": ["#00786a", "#006e60", "149d8c"],
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48.179" height="45.247">
            <g transform="translate(-326 -859.61)">
                <path
                    d="M350.039 859.61a8.157 8.157 0 00-8.119 7.382 8.156 8.156 0 000 16.239 8.156 8.156 0 0016.239 0 8.156 8.156 0 000-16.239 8.157 8.157 0 00-8.12-7.382z"
                    fill={colors[color][0]}
                />
                <path
                    fill={colors[color][1]}
                    d="M350.19 904.351s24.251 5.114 23.987-17.165c0-.001-18.83-5.179-23.987 17.165zM349.99 904.351s-24.251 5.114-23.987-17.165c-.002-.001 18.83-5.179 23.987 17.165z"
                />
                <path
                    d="M342.021 867.093a5.968 5.968 0 00-.381 8.018 5.966 5.966 0 008.4 8.4 5.966 5.966 0 008.4-8.4 5.966 5.966 0 00-8.4-8.4 5.968 5.968 0 00-8.019.382z"
                    fill={colors[color][2]}
                    opacity=".65"/>
                <circle cx="4" cy="4" r="4" transform="translate(346 871)" fill="#fff"/>
            </g>
        </svg>
    )
}

export default FlowerIcon;
