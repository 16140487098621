import React from "react";

const LogoPalace = ({ className, style}) => {
return (
    <svg className={className} style={style} width="200" height="84" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.04 24" fill="#fff">
        <path d="M12.83 19.53h1.89a1.71 1.71 0 011.16.36 1.23 1.23 0 01.42 1 1.27 1.27 0 01-.42 1 1.73 1.73 0 01-1.16.36h-1.46v2.2h-.43zm1.87 2.34a1.31 1.31 0 00.87-.26 1 1 0 000-1.45 1.31 1.31 0 00-.87-.26h-1.44v2z" transform="translate(-.33 -.5)"/><path d="M21.89 24.44l-.56-1.31h-2.65l-.56 1.31h-.45l2.14-4.91h.39l2.13 4.91zm-3.05-1.68h2.32L20 20.05z" transform="translate(-.33 -.5)"/><path d="M24.52 24.44v-4.91H25v4.54h2.54v.37z" transform="translate(-.33 -.5)"/><path d="M33.24 24.44l-.56-1.31H30l-.56 1.31H29l2.13-4.91h.39l2.13 4.91zm-3.05-1.68h2.33l-1.16-2.71z" transform="translate(-.33 -.5)"/><path d="M36.56 24.2a2 2 0 01-.78-.87 3.61 3.61 0 010-2.69 2 2 0 01.78-.86 2.25 2.25 0 011.18-.3 2.61 2.61 0 01.94.16 2.11 2.11 0 01.73.48l-.18.34a2.34 2.34 0 00-.7-.46 2.22 2.22 0 00-.79-.14 1.62 1.62 0 00-1.31.55A2.34 2.34 0 0036 22a2.4 2.4 0 00.46 1.58 1.63 1.63 0 001.32.56 2.22 2.22 0 00.79-.14 2.34 2.34 0 00.7-.46l.18.34a2.11 2.11 0 01-.73.48 2.61 2.61 0 01-.94.16 2.25 2.25 0 01-1.22-.32z" transform="translate(-.33 -.5)"/><path d="M41.8 24.44v-4.91h3v.37h-2.58v1.86h2.47v.38h-2.47v1.94h2.61v.36z" transform="translate(-.33 -.5)"/>
        <path d="M48.75 11.44v11l2.13-1.13v-9.87a2.18 2.18 0 112.18 2.18v2.14a4.32 4.32 0 10-4.31-4.32" transform="translate(-.33 -.5)"/>
        <path d="M44.94 7.08v4.31a1.94 1.94 0 01-1.88 2.17 2.17 2.17 0 01-2.17-2.17V7.08h-2.22v4.31a4.4 4.4 0 004.39 4.39 4.14 4.14 0 004.09-4.39V7.08z" transform="translate(-.33 -.5)"/>
        <path d="M34.89 11.44V9.21h2.22V7.08h-2.22V4.44l-2.14 1.1v5.9a4.36 4.36 0 004.36 4.36v-2.14a2.22 2.22 0 01-2.22-2.22z" transform="translate(-.33 -.5)"/>
        <path d="M26.82 11.44v4.34H29v-4.34a2.22 2.22 0 012.22-2.22V7.08a4.36 4.36 0 00-4.4 4.36z" transform="translate(-.33 -.5)"/>
        <path d="M20.92 7.13a4.23 4.23 0 00-3.05 1.27 4.31 4.31 0 003.68 7.31v-2.18a2.18 2.18 0 11-.5-4.26 2.17 2.17 0 012.05 2.17v4.34h2.14v-4.34a4.32 4.32 0 00-4.32-4.31z" transform="translate(-.33 -.5)"/>
        <path d="M15 7.1h-2.19V4.44l-2.13 1.11v5.89A4.36 4.36 0 0015 15.8v-2.14a2.22 2.22 0 01-2.22-2.22v-2.2H15z" transform="translate(-.33 -.5)"/>
        <path d="M4.69 7a2.18 2.18 0 01-2.22-2.14 2.22 2.22 0 014.44 0l2-1a4.36 4.36 0 10-4.25 5.4 2.17 2.17 0 11-2.16 2.15L.42 12.48A4.38 4.38 0 104.69 7z" transform="translate(-.33 -.5)"/>
    </svg>
)}

export default LogoPalace;


