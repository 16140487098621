module.exports = {
    fr: {
        path: 'fr',
        locale: 'Français',
        default: true
    },
    // en: {
    //   path: 'en',
    //   locale: 'English',
    //   routing: {
    //       "/": "/",
    //       "/accelerateur/": "/accelerator/",
    //       "/equipe/": "/team/",
    //       "/programme/": "/program/",
    //       "/thematiques/": "/thematics/",
    //       "/mentions-legales/": "/thematics/"
    //   }
    // }
}